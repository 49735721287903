import { Component, BaseComponent } from '@zento-lib/components';
import { BlogStore } from 'theme/stores/blog/blog';

@Component({})
export class BlogMixin extends BaseComponent<unknown, unknown> {
  private blogStore = new BlogStore();

  /**
   * Fetch blog articles on server side
   */
  async serverPrefetch() {
    return await this.fetchBlogData();
  }

  /**
   * Fetches blog articles data
   */
  async fetchBlogData(storeId?: number) {
    return this.blogStore.runInParallel(this.blogStore.fetchBlogPosts(storeId), this.blogStore.fetchBlogCategories());
  }

  beforeMount() {
    this.onBroadcast('store-view-changed', async (storeId) => {
      await this.fetchBlogData(storeId);
    });
  }

  async mounted() {
    if (!this.blogStore.blogArticles.length) {
      await this.fetchBlogData();
    }
  }

  beforeDestroy() {
    this.offBroadcast('store-view-changed');
  }

  get blogArticles() {
    return this.blogStore.blogArticles;
  }

  get blogArticlesCats() {
    return this.blogStore.categories;
  }

  blogCatName(id: string) {
    return this.blogStore.getCategoryByIdentifier(parseInt(id))?.title || '';
  }
}
