import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';
import type { ExtractArrayType } from '@zento-lib/util/types';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

import {
  fetchBlogPosts,
  FetchBlogPostsQuery,
  fetchBlogCategories,
  FetchBlogCategoriesQuery,
} from './operations.graphql';

export type BlogArticle = ExtractArrayType<FetchBlogPostsQuery['blogPosts']>;
export type BlogCategory = ExtractArrayType<FetchBlogCategoriesQuery['blogCategories']>;

@Store
export class BlogStore extends BaseStore {
  /**
   * Blog articles types
   */
  @Field
  private blogData: BlogArticle[];

  /**
   * Blog categories
   */
  @Field
  private blogCategories: BlogCategory[];

  /**
   * Fetch Blog Articles
   */
  public async fetchBlogPosts(storeId?: number) {
    let posts: FetchBlogPostsQuery = { blogPosts: [] };

    if (!storeId) {
      storeId = currentStoreView().storeId;
    }

    try {
      posts = await this.dataSources.graphQl.queue({
        ...fetchBlogPosts,
        params: { storeId },
      });
    } catch (e) {
      console.error('Blog Store (fetchBlogPosts): ', e);
    }

    if (posts && posts.blogPosts.length) {
      this.blogData = posts.blogPosts.filter((post) => post.published);
    }

    return this.blogData;
  }

  /**
   * Fetch Blog Categories
   */
  public async fetchBlogCategories() {
    let categories: FetchBlogCategoriesQuery = { blogCategories: [] };

    try {
      categories = await this.dataSources.graphQl.queue({
        ...fetchBlogCategories,
        params: { storeId: currentStoreView().storeId },
      });
    } catch (e) {
      console.error('Blog Store (fetchBlogCategories): ', e);
    }

    this.blogCategories = categories.blogCategories.filter((cat) => !!cat.is_active);

    return this.blogCategories;
  }

  /**
   * All Blog Articles getter
   */
  public get blogArticles() {
    return this.blogData || [];
  }

  /**
   * All Blog Categories getter
   */
  public get categories() {
    return this.blogCategories || [];
  }

  /**
   * Retrieve specific blog category data by it's identifier
   */
  public getCategoryByIdentifier(id: number): BlogCategory | null {
    return this.categoriesMap[id] || null;
  }

  /**
   * Compute a map of blog categories id to blog categories values in order to allow fast selection
   */
  private get categoriesMap() {
    return (this.blogCategories || []).reduce((ret, i) => ({ ...ret, [i.entity_id]: i }), {});
  }
}
